import * as Sentry from '@sentry/browser';
import { writable } from 'svelte/store';
import { browser } from '$app/environment';
import { getDeviceId } from '$lib/utils/device-id';

/**
 * @typedef {{[key:string]: any }} UserStoreState
 * @property {boolean} tokenValid
 * @property {string} token
 * @property {boolean} legacyExperience
 * @property {string} accountStatus
 * @property {string} userToProductUuid
 * @property {string} paymentProvider
 * @property {object} userMetaData
 * @property {string} maskedEmail
 * @property {string} tmpEmail
 * @property {string} tmpPhone
 * @property {boolean} debugUser
 * @property {boolean} isActive
 * @property {boolean} isExisting
 * @property {string|null} error
 */

/**
 * @typedef {{[key:string]: string}} ApiResponse
 * @property {boolean} token_valid
 * @property {string} token
 * @property {boolean} legacy_experience
 * @property {string} account_status
 * @property {string} user_to_product_uuid
 * @property {string} payment_provider
 * @property {object} user_meta_data
 * @property {string} masked_email
 * @property {string} tmp_email
 * @property {boolean} debug_user
 */

/**
 * // TODO: update this for nested keys
 * Converts known keys to camelCase and any unknown keys as well.
 * @param {ApiResponse} obj - The object to convert.
 * @param {{[key:string]: string}} keyMap - The mapping of known keys.
 * @returns {Partial<UserStoreState>} - The object with camelCase keys.
 */
const toCamelCase = (obj, keyMap) => {
	/** @type {Partial<UserStoreState>} */
	const camelCaseObj = {};

	for (const key in obj) {
		if (keyMap[key]) {
			camelCaseObj[keyMap[key]] = obj[key];
		} else {
			const camelCaseKey = key.replace(/_([a-z])/g, (match, p1) => p1.toUpperCase());
			camelCaseObj[camelCaseKey] = obj[key];
		}
	}

	return camelCaseObj;
};

/** @type {UserStoreState} */
const initialState = {
	tokenValid: false,
	token: '',
	legacyExperience: false,
	accountStatus: '',
	userToProductUuid: '',
	paymentProvider: '',
	userMetaData: {},
	maskedEmail: '',
	tmpEmail: browser ? sessionStorage?.getItem('tmpEmail') || '' : '',
	tmpPhone: browser ? sessionStorage?.getItem('tmpPhone') || '' : '',
	debugUser: false,
	isFirstLogin: null,
	isActive: null,
	isExisting: null,
	error: null,
	isReactivated: false
};

/**
 * User store.
 * @type {import('svelte/store').Writable<UserStoreState>} - user store
 */
export const user = writable(initialState);

/**
 * Updates the store with successful data.
 * @param {ApiResponse} data - The data to update the store with.
 */
export const publishSuccess = (data) => {
	// TODO: clean up so unknown keys are still available in the store.
	const knownKeys = {
		token_valid: 'tokenValid',
		token: 'token',
		legacy_experience: 'legacyExperience',
		account_status: 'accountStatus',
		user_to_product_uuid: 'userToProductUuid',
		payment_provider: 'paymentProvider',
		user_meta_data: 'userMetaData',
		masked_email: 'maskedEmail',
		tmp_email: 'tmpEmail',
		tmpPhone: 'tmpPhone',
		debug_user: 'debugUser',
		password: 'password',
		is_reactivated: false
	};

	const userData = {
		...toCamelCase(data, knownKeys),
		isActive:
			data?.account_status === 'active' ||
			data?.is_active ||
			data?.account_status === 'auxiliary_only_active', // TODO: cleanup and make this more clear
		isExisting: data?.account_status === 'inactive' || data?.is_existing
	};
	user.update((state) => ({ ...state, ...userData, error: null }));

  const sentryUser = {
    id: userData.userToProductUuid ? userData.userToProductUuid : getDeviceId(),
    ...userData,
  };
	Sentry.setUser(sentryUser);
};

/**
 * Updates the store with an error.
 * @param {Error} error - The error object.
 */
export const publishFailure = (error) => {
	user.update((state) => ({
		...state,
		error: error.cause
	}));
};

export const resetStore = () => {
	user.update((state) => ({
		...state,
		...initialState
	}));

  Sentry.setUser(null);
};
