<script>
	import { closeModal, modals } from 'svelte-modals';
	import { onMount } from 'svelte';
	import { createEventDispatcher } from 'svelte';

	export let hideHeader = false;
	export let hideModalClose = false;
	export let id = '';
	export let onClose = () => {
		closeModal();
	};

	const dispatch = createEventDispatcher();

	const graphicCloseButton = `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
  <path d="M14.1311 11.8408L21.1947 4.77529C21.3855 4.59096 21.5377 4.37047 21.6425 4.12669C21.7472 3.8829 21.8023 3.6207 21.8046 3.35538C21.8069 3.09007 21.7564 2.82695 21.6559 2.58138C21.5554 2.33581 21.4071 2.11271 21.2194 1.9251C21.0318 1.73748 20.8087 1.58911 20.5632 1.48864C20.3176 1.38817 20.0545 1.33761 19.7891 1.33992C19.5238 1.34222 19.2616 1.39735 19.0178 1.50207C18.7741 1.60679 18.5536 1.75902 18.3692 1.94986L11.3037 9.01342L4.24013 1.94986C4.05581 1.75902 3.83532 1.60679 3.59153 1.50207C3.34775 1.39735 3.08554 1.34222 2.82023 1.33992C2.55491 1.33761 2.29179 1.38817 2.04622 1.48864C1.80066 1.58911 1.57755 1.73748 1.38994 1.9251C1.20233 2.11271 1.05396 2.33581 0.953485 2.58138C0.853015 2.82695 0.802457 3.09007 0.804763 3.35538C0.807068 3.6207 0.862191 3.8829 0.966913 4.12669C1.07164 4.37047 1.22386 4.59096 1.41471 4.77529L8.47827 11.8388L1.41471 18.9044C1.22386 19.0887 1.07164 19.3092 0.966913 19.553C0.862191 19.7968 0.807068 20.059 0.804763 20.3243C0.802457 20.5896 0.853015 20.8527 0.953485 21.0983C1.05396 21.3439 1.20233 21.567 1.38994 21.7546C1.57755 21.9422 1.80066 22.0906 2.04622 22.191C2.29179 22.2915 2.55491 22.3421 2.82023 22.3398C3.08554 22.3375 3.34775 22.2823 3.59153 22.1776C3.83532 22.0729 4.05581 21.9207 4.24013 21.7298L11.3037 14.6663L18.3692 21.7298C18.5536 21.9207 18.7741 22.0729 19.0178 22.1776C19.2616 22.2823 19.5238 22.3375 19.7891 22.3398C20.0545 22.3421 20.3176 22.2915 20.5632 22.191C20.8087 22.0906 21.0318 21.9422 21.2194 21.7546C21.4071 21.567 21.5554 21.3439 21.6559 21.0983C21.7564 20.8527 21.8069 20.5896 21.8046 20.3243C21.8023 20.059 21.7472 19.7968 21.6425 19.553C21.5377 19.3092 21.3855 19.0887 21.1947 18.9044L14.1311 11.8408Z" fill="#0444B2"/>
</svg>`;

	/**
	 * @param {BlobPart} svgString
	 */
	function getImageFromSVG(svgString) {
		const svgBlob = new Blob([svgString], { type: 'image/svg+xml' });
		return URL.createObjectURL(svgBlob);
	}

	/**
	 * Event handler for keyboard events.
	 *
	 * @param {KeyboardEvent} evt - The keyboard event object.
	 * @returns {void}
	 */
	const evtHandler = (evt) => {
		dispatch('keydown', evt);
		return;
	};

	onMount(() => {
		// TODO: common utitlity to handle this.
		if (typeof document !== 'undefined') {
			modals.subscribe((modals) => {
				if (modals.length > 0) {
					document.documentElement.style.setProperty('overflow-y', 'hidden');
				} else {
					document.documentElement.style.setProperty('overflow-y', 'visible');
				}
			});
		}
	});
</script>

<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
<aside {id} on:keydown={evtHandler} role="dialog" class="modal-base w-[30rem] sm:w-[60rem]">
	<header
		class="mb-0 flex h-[5rem] flex-row items-center rounded-t-[3rem] bg-electricYellow shadow-lg sm:h-[unset] sm:w-[60rem]"
		class:hidden={hideHeader}
	>
		<slot name="modal-offer-burst" />
		<slot name="modal-header-title" />
		<slot name="modal-focus" />
		{#if !hideModalClose}
			<button on:click={onClose} id="modal-close-btn">
				<img
					class="modal-close-button m-5 p-2"
					height="10"
					width="10"
					src={getImageFromSVG(graphicCloseButton)}
					alt="Modal Close"
				/>
			</button>
		{:else}
			<slot name="modal-close-button" />
		{/if}
	</header>
	<slot name="modal-content" />
	<slot name="modal-focus" />
</aside>
