<script>
	import ModalBase from './ModalBase.svelte';
</script>

<ModalBase id="modal-captcha" hideHeader>
	<div slot="modal-content" class="flex justify-center">
		<div
			class="flex h-auto p-[3rem] w-[30rem] flex-col items-center rounded-[3rem] bg-white px-[2rem] sm:w-[60rem]"
		>
			<h2 class="mb-2 mt-[1rem] text-[2.8rem] text-oceanBlue sm:mb-5 sm:mt-[3rem] sm:text-[5rem]">
				Hello!
			</h2>
			<p class="mb-4 text-center text-[1.5rem] font-medium text-grey-800 sm:mb-8 sm:text-[2.8rem]">
				Please verify you are a human:
			</p>
			<div id="px-captcha"></div>
		</div>
	</div>
</ModalBase>
