import { openModal, closeAllModals } from 'svelte-modals';
import ModalCaptcha from '$lib/components/modals/ModalCaptcha.svelte';
import { AnalyticsService } from '$lib/services/analytics';
import * as Sentry from '@sentry/browser';
import { user } from '$lib/stores/user/store';
import { get } from 'svelte/store';

class CaptchaService {
	/**
	 * Uses the response from the HUMAN API interception to set the window properties and add the captcha script.
	 * @param {object} apiResponse - The response object from the API.
	 */
  static parseCaptchaResponse(apiResponse) {
    this.setResponseListener();
    this.setWindowProperties(apiResponse);
    this.addCaptchaScript(apiResponse.blockScript);
  }

	/**
	 * Adds the captcha script to the document.
	 * @param {string} blockScriptSrc - The source URL of the captcha script.
	 */
  static addCaptchaScript(blockScriptSrc) {
    const script = document.createElement('script');
    script.src = blockScriptSrc;
    document.getElementsByTagName('head')[0].appendChild(script);
    script.addEventListener('load', () => {
      this.showCaptchaModal();
    });
    script.addEventListener('error', (event) => {
      Sentry.captureException('Failed to load captcha script', {
          extra: {
            src: blockScriptSrc,
            eventType: event.type,
            target: event.target?.tagName,
            outerHTML: event.target?.outerHTML,
          }
      });
    });
  }

	/**
	 * Sets the window properties for the captcha script.
	 * @param {object} apiResponse - The response object from the API.
	 */
  static setWindowProperties(apiResponse) {
    window._pxAppId = apiResponse?.appId;
    window._pxJsClientSrc = apiResponse?.jsClientSrc;
    window._pxFirstPartyEnabled = apiResponse?.firstPartyEnabled;
    window._pxVid = apiResponse?.vid;
    window._pxUuid = apiResponse?.uuid;
    window._pxHostUrl = apiResponse?.hostUrl;
    window._pxreCaptchaTheme = 'light';
  }

  /**
   * Sets the response listener for the captcha script.
   * @static
   */
  static setResponseListener() {
    window._pxOnCaptchaSuccess = (isValid) => {
      if (isValid) {
        this.hideCaptchaModal();
      }
    };
  }

  /**
   * Shows the captcha modal.
   * @static
   */
  static async showCaptchaModal() {
		openModal(ModalCaptcha);

    const email = get(user).tmpEmail;

    try {
      await AnalyticsService.trackClientDebug({
        email: email || '',
        recaptcha: 'show'
      });
    } catch (err) {
      Sentry.captureException('Captcha Service showCaptchaModal error', {
        extra: {
          errors: err
        }
      });
    }
  }

  /**
   * Hides the captcha modal.
   * @static
   */
  static async hideCaptchaModal() {
    closeAllModals();
    try {
      AnalyticsService.trackClientDebug({
        recaptcha: 'hide'
      });
    } catch (err) {
      Sentry.captureException('Captcha Service hideCaptchaModal error', {
        extra: {
          errors: err
        }
      });
    }
  }
}

export { CaptchaService };



