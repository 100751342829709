import {
	AOFL_PRODUCT_NAMES,
	EVENT_NAMES,
	QUERY_PARAM_KEYS,
	LOCAL_STORAGE_KEYS,
	COOKIES
} from '$lib/utils/constants';
import {
	getJSPath,
	isCustomTrackedElement,
	hashElementPath,
	trackedElems
} from '$lib/utils/click-tracking';
import { v4 as uuidv4 } from 'uuid';
import { ApiService } from '$lib/services/api';
import { getDeviceId } from '$lib/utils/device-id';
import * as Sentry from '@sentry/sveltekit';
import Cookies from 'js-cookie';
import { get } from 'svelte/store';
import { app } from '$lib/stores/app/store';
import { isExperimentArmActive } from '$lib/services/experiments';

/**
 *
 */
class AnalyticsService {
	/**
	 * Handles click tracking for analytics.
	 * @param {Event} e - The click event.
	 * @returns {Promise<void>} - A promise that resolves when the event is tracked.
	 */
	static async clickTrackingHandler(e) {
		e.stopPropagation();

		try {
			let targetElement = e.target;

			// Traverse up the DOM to find the closest tracked element
			while (targetElement && targetElement !== document) {
				if (trackedElems.includes(targetElement.tagName) || isCustomTrackedElement(targetElement)) {
					break;
				}
				targetElement = targetElement.parentElement;
			}

			// If no tracked element is found, exit the handler
			if (!targetElement || targetElement === document) return;

			const pageName = AnalyticsService.createPageName();
			const elementPath = getJSPath(targetElement);
			let linkId = isCustomTrackedElement(targetElement);

			if (!linkId) {
				const hashedPath = await hashElementPath(elementPath);
				linkId = hashedPath.slice(0, 8);
			}

			const appMeta = get(app)?.meta || {};
			const event = {
				event_type: EVENT_NAMES.LINK_CLICK,
				device_id: getDeviceId(),
				event_payload: {
					aofl_product: AOFL_PRODUCT_NAMES.ABCMOUSE,
					link_info: {
						...appMeta,
						track_cookie: Cookies.get(COOKIES.TRACK_COOKIE) || 'none',
						link_id: linkId,
						experiment_id: Cookies.get(COOKIES.EXPERIMENTS),
						app_version: __APP_VERSION__, //eslint-disable-line
						element_path: elementPath,
						page_name: pageName,
						site_section: 'regpath'
					}
				}
			};

			return AnalyticsService.fireEvent({ event });
		} catch (e) {
			Sentry.captureException(e, {
				extra: {
					action: 'Error: Click Tracking Handler'
				}
			});
			console.error(e);
		}
	}

	/**
	 * @returns {string} The created page name.
	 */
	static createPageName() {
		if (location.pathname === '/') return 'home';
		return location.pathname.replace(/^\//, '').replace(/\/$/, '').replace(/\//g, '-');
	}

	/**
	 * Sets up a click tracking listener on the document.
	 * @returns {Promise<void>} A promise that resolves when the listener is set up.
	 */
	static async setClickTrackingListener() {
		document.addEventListener('click', AnalyticsService.clickTrackingHandler);
	}

	/**
	 * Removes the app navigation listeners.
	 */
	static removeAppNavigationListeners() {
		const visibilityChangeEvent =
			'onvisibilitychange' in document ? 'visibilitychange' : 'pagehide';
		document.removeEventListener(visibilityChangeEvent, AnalyticsService.visibilityChangeHandler);
	}

	/**
	 * Removes the click tracking listener.
	 */
	static removeClickTrackingListener() {
		document.removeEventListener('click', AnalyticsService.clickTrackingHandler);
	}

	/**
	 * Tracks a client validation event.
	 * @param {object} [options] - The optional options object.
	 * @param {object} [options.form] - An optional form object.
	 * @param {string} [options.component] - An optional component name.
	 * @returns {Promise<void>} A promise that resolves when the tracking is complete.
	 */
	static async trackClientValidation(options = {}) {
		const searchParams = new URLSearchParams(location.search);
		const params = {};
		for (const [key, value] of searchParams.entries()) {
			params[key] = value;
		}

		const appMeta = get(app)?.meta || {};
		const event = {
			event_type: EVENT_NAMES.CLIENT_VALIDATION,
			device_id: getDeviceId(),
			event_payload: {
				aofl_product: AOFL_PRODUCT_NAMES.ABCMOUSE,
				data: {
					device_info: { ...appMeta, user_agent: navigator?.userAgent },
					form: options.form,
					component: options.component,
					experiment_id: Cookies.get(COOKIES.EXPERIMENTS),
					track_cookie: Cookies.get(COOKIES.TRACK_COOKIE) || 'none',
					site_section: 'regpath',
					page_url: location.origin + location.pathname
				},
				url_variable_info: params
			}
		};

		return AnalyticsService.fireEvent({ event });
	}

	/**
	 * Tracks experiment assignment events for analytics.
	 * @param {object} options - The options for tracking the experiment assignment
	 * @param {string} [options.customPageName] - Optional custom page name to override default
	 * @param {string} [options.rotationId] - The ID of the rotation/experiment being tracked
	 * @returns {Promise<void>} A promise that resolves when the event is tracked
	 */
	static async trackExperimentAsssignment(options = {}) {
		// Get page name from options or generate default
		const { customPageName } = options;
		const pageName = customPageName ?? AnalyticsService.createPageName();

		// Extract URL search params into object
		const searchParams = new URLSearchParams(location.search);
		const params = {};
		for (const [key, value] of searchParams.entries()) {
			params[key] = value;
		}

		// Get app metadata from store
		const appMeta = get(app)?.meta || {};

		// Construct the experiment assignment event
		const event = {
			event_type: EVENT_NAMES.EXPERIMENT_ASSIGNMENT,
			device_id: getDeviceId(),
			event_payload: {
				aofl_product: AOFL_PRODUCT_NAMES.ABCMOUSE,
				rotation_info: {
					device_info: { ...appMeta, user_agent: navigator?.userAgent },
					track_cookie: Cookies.get(COOKIES.TRACK_COOKIE) || 'none',
					rotation_id: options.rotationId,
					site_section: 'regpath',
					page_name: pageName,
					page_url: location.origin + location.pathname
				},
				url_variable_info: params
			}
		};

		// Fire the event through analytics service
		return AnalyticsService.fireEvent({ event });
	}

	/**
	 * Tracks a client validation event.
	 * @param {object} [options] - The optional options object.
	 * @returns {Promise<void>} A promise that resolves when the tracking is complete.
	 */
	static async trackClientDebug(options = {}) {
		const searchParams = new URLSearchParams(location.search);
		const params = {};
		for (const [key, value] of searchParams.entries()) {
			params[key] = value;
		}

		const appMeta = get(app)?.meta || {};
		const event = {
			event_type: EVENT_NAMES.DEBUG,
			device_id: getDeviceId(),
			event_payload: {
				aofl_product: AOFL_PRODUCT_NAMES.ABCMOUSE,
				data: {
					debug_info: { ...options },
					device_info: { ...appMeta, user_agent: navigator?.userAgent },
					experiment_id: Cookies.get(COOKIES.EXPERIMENTS),
					track_cookie: Cookies.get(COOKIES.TRACK_COOKIE) || 'none',
					site_section: 'regpath',
					page_url: location.origin + location.pathname
				},
				url_variable_info: params
			}
		};

		return AnalyticsService.fireEvent({ event });
	}

	/**
	 * Tracks a third-party conversion event.
	 * @param {object} [options] - The optional options object.
	 * @returns {Promise<void>} A promise that resolves when the tracking is complete.
	 */
	static async trackThirdPartyConversion(options = {}) {
    if (get(app)?.meta?.inApp) return;

		try {

			const payload = {
				conversion_event_id: options.conversion_event_id || '',
        user_to_product_uuid: options.user_to_product_uuid || '',
				country_code: options.country_code || 'US',
				event_source: options.event_source || '',
				event_source_url: 'https://www.abcmouse.com',
				event_type: options.event_type,
				hashed_email: options.email ? await AnalyticsService.hashValue(options.email) : '',
				user_agent: window.navigator.userAgent
			};

      AnalyticsService.trackClientDebug({
        ...payload,
        debug: 'third_party_conversion_call',
      });

			return ApiService.logThirdPartyConversion(payload);
		} catch (err) {
			Sentry.captureException(err, {
				extra: { action: 'Error: Track Third Party Conversion' }
			});
		}
	}

	/**
	 * Tracks a page view event.
	 * @param {object} [options] - The optional options object.
	 * @param {string} [options.customPageName] - An optional custom page name.
	 * @returns {Promise<void>} A promise that resolves when the tracking is complete.
	 */
	static async trackPageView(options = {}) {
		const { customPageName } = options;
		const pageName = customPageName ?? AnalyticsService.createPageName();
		console.log('pageName', pageName);
		if (pageName === 'abc-subscription') {
			isExperimentArmActive('10005');
			isExperimentArmActive('10006');
			isExperimentArmActive('10007');
			isExperimentArmActive('10008');
			isExperimentArmActive('10023');
			isExperimentArmActive('10024');
		}
		const searchParams = new URLSearchParams(location.search);
		const params = {};
		for (const [key, value] of searchParams.entries()) {
			params[key] = value;
		}

		const appMeta = get(app)?.meta || {};
		const event = {
			event_type: EVENT_NAMES.PAGE_LOAD,
			device_id: getDeviceId(),
			event_payload: {
				aofl_product: AOFL_PRODUCT_NAMES.ABCMOUSE,
				page_info: {
					...appMeta,
					track_cookie: Cookies.get(COOKIES.TRACK_COOKIE) || 'none',
					site_section: 'regpath',
					page_name: pageName,
					page_url: location.origin + location.pathname,
					view_info: []
				},
				url_variable_info: params,
				referrer: ''
			}
		};
		return AnalyticsService.fireEvent({ event });
	}

	/**
	 * TEMPORARY
	 * Tracks a migration redirect event, for old system users
	 * @param {object} [payload] - The optional options object.
	 * @returns {Promise<void>} A promise that resolves when the tracking is complete.
	 */
	static async trackMigrationRedirect(payload = {}) {
		const event = {
			event_type: EVENT_NAMES.MIGRATION_REDIRECT,
			device_id: getDeviceId(),
			event_payload: {
				aofl_product: AOFL_PRODUCT_NAMES.ABCMOUSE,
				data: {
					experiment_id: '',
					app_version: __APP_VERSION__, //eslint-disable-line
					current_url: payload.currentUrl,
					user_type: payload.userType
				}
			}
		};

		return AnalyticsService.fireEvent({ event });
	}

	/**
	 * TEMPORARY
	 * Tracks a migration redirect landing in the new system from the old system
	 * @returns {Promise<void>} A promise that resolves when the tracking is complete.
	 */
	static async trackMigrationRotation() {
		const queryParams = new URLSearchParams(location.search);
		const appMeta = get(app)?.meta || {};
		let experimentInfo = {};
		let experimentArm = '';

		try {
			experimentInfo = JSON.parse(decodeURIComponent(Cookies.get(COOKIES.EXPERIMENTS)));
			experimentArm = experimentInfo['10000']?.id;
		} catch (e) {
			// Fallback, hardcoding test values
			experimentArm = '9105';
			experimentInfo = {
				requestId: 'FALLBACK',
				first_assignment: true,
				disallowed: false
			};

			Sentry.captureException(e, {
				extra: {
					cookies: document.cookie,
					action: 'Error: Decoding Experiment Info Cookie'
				}
			});
		}

		const event = {
			event_type: EVENT_NAMES.MIGRATION_ROTATION,
			device_id: getDeviceId(),
			event_payload: {
				aofl_product: AOFL_PRODUCT_NAMES.ABCMOUSE,
				data: {
					requestId: experimentInfo?.requestId || 'N/A',
					first_assignment: experimentInfo?.first_assignment || 'N/A',
					forced_assignment: {
						forced: experimentInfo?.disallowed || 'N/A',
						reason: experimentInfo?.reason || 'N/A'
					},
					forced_arm_param: queryParams?.get('arm') || 'N/A',
					arm: experimentArm || 'N/A',
					navigatorPlatform: navigator?.platform,
					device_info: { ...appMeta, user_agent: navigator?.userAgent },
					cookies: document.cookie,
					experiment_cookie: Cookies.get(COOKIES.EXPERIMENTS),
					app_version: __APP_VERSION__, //eslint-disable-line
					landing_url: window.location.href,
					user_type:
						Cookies.get(COOKIES.MIGRATION_USER_TYPE) || queryParams?.get('user_type') || 'N/A',
					track_cookie:
						Cookies.get(COOKIES.TRACK_COOKIE) || queryParams.get('track_cookie') || 'N/A'
				}
			}
		};

		return AnalyticsService.fireEvent({ event });
	}

	/**
	 *
	 * Tracks an email click event if email click query param is present.
	 * @returns {Promise<void>} A promise that resolves when the tracking is complete.
	 */
	static async trackEmailClick() {
		const urlSearchParam = new URLSearchParams(location.search);
		const emailClickParam = urlSearchParam.get(QUERY_PARAM_KEYS.EMAIL_CLICK);

		if (!emailClickParam || typeof emailClickParam !== 'string') return;

		const payload = {
			request_url: location.href
		};

		try {
			await ApiService.receipientLogEmailClick(payload);
		} catch (err) {
			Sentry.captureException(err, {
				extra: {
					action: 'Error: Analytics Service: Track email click'
				}
			});
		}
	}

	/**
	 *
	 * Fires internal pixels if email click query param is present.
	 * @param {string} event - The event to fire.
	 * @param {object} replacements - The replacements for the event.
	 * @returns {Promise<void>} A promise that resolves when the tracking is complete.
	 */
	static async fireInternalPixels(event, replacements = {}) {
		const {pixelsInternal} = await import('./pixels');

    if (pixelsInternal[event]) {
      const internalPixels = pixelsInternal[event];

      for (let i = 0; i < internalPixels.length; i++) {
        for (const key in replacements) {
          if (replacements.hasOwnProperty(key)) {
            const regex = new RegExp('{{' + key + '}}', 'gi');
            internalPixels[i] = internalPixels[i].replace(regex, replacements[key]);
          }
        }

				try {
					await fetch(internalPixels[i], {
						method: 'GET',
						headers: {
							'Content-type': 'application/json;charset=UTF-8',
							'Access-Control-Allow-Origin': '*',
						},
						mode: 'no-cors'
					});
				} catch (err) {
					Sentry.captureException(err, {
						extra: {
							action: 'Error: Analytics Service: Fire Internal Pixels'
						}
					});
				}
      }
    }
	}

	/**
	 * @returns {void}
	 */
	static thirdPartyPageLoad() {
		const page = AnalyticsService.createPageName();
		try {
			// Google Analytics
			if (window.gtag) {
				window.gtag('set', 'page', page);
				window.gtag('send', 'pageview');
			}

			// Bing UET
			window.uetq = window.uetq || [];
			window.uetq.push('event', 'page_view', { page_path: page });
		} catch (err) {
			Sentry.captureException(err, {
				extra: {
					action: 'Error: Third Party Page Load'
				}
			});
		}
	}

	/**
	 * @param {string} string string to hash
	 * @returns {string}
	 */
	static async hashValue(string) {
		const utf8 = new TextEncoder().encode(string);
		const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
		const hashArray = Array.from(new Uint8Array(hashBuffer));
		const hashHex = hashArray.map((bytes) => bytes.toString(16).padStart(2, '0')).join('');
		return hashHex;
	}

	/**
	 * Sends a beacon with the provided data.
	 * @param {object} data - The data to be sent.
	 * @returns {boolean} - Returns true if the beacon was successfully sent, otherwise false.
	 */
	static async sendBeacon(data) {
		const payload = ApiService.pack(data);
		return navigator.sendBeacon(`/ws/amsl/0.1/json/Event/Log/init`, payload);
	}

	/**
	 * Pushes data to the dataLayer for analytics tracking.
	 * @param {object} data - The data to be pushed to the dataLayer.
	 * @param {string} data.eventName - The name of the event.
	 * @param {string} [data.product] - The product hash (optional).
	 * @param {string} [data.email] - The hashed email (optional).
	 * @param {string} [data.currency] - The currency (optional).
	 */
	static async dataLayerPush(data) {
    if (get(app)?.meta?.inApp) return;

		let experimentInfo = {};
		try {
			window.dataLayer = window.dataLayer || [];
			const appMeta = get(app)?.meta;

			let deviceType = 'Desktop';
			if (appMeta.isIosApp || appMeta.isIosViewer) {
				deviceType = 'iOS';
			} else if (appMeta.isAndroidApp || appMeta.isAndroidViewer) {
				deviceType = 'Android';
			}

			try {
				const experimentsJson = localStorage.getItem(LOCAL_STORAGE_KEYS.EXPERIMENTS);
				experimentInfo = JSON.parse(experimentsJson) || {};
			} catch (err) {
				Sentry.captureException(err, {
					extra: {
						action: 'Error: Decoding Experiment Info'
					}
				});
			}

			window.dataLayer.push({
				id: data?.event_id || uuidv4(),
				event: data?.eventName,
				eventData: {
					product: data?.product,
					timestamp: Date.now(),
					sourceTag: AnalyticsService.getSourceTag(),
					userAgent: navigator.userAgent,
					geo: appMeta.viewerCountry,
					externalID: Cookies.get(COOKIES.CJ_COOKIE) || data?.event_id || uuidv4(),
					hashedEMail: await AnalyticsService.hashValue(data?.email),
					pageUrl: location.href,
					inApp: appMeta.inApp,
					deviceType,
					experimentInfo,
					clientVersion: __APP_VERSION__, //eslint-disable-line
					currency: data?.currency
				}
			});
		} catch (err) {
			Sentry.captureException(err, {
				extra: {
					action: 'Error: Data Layer Push'
				}
			});
		}
	}

	/**
	 * Retrieves the source tag from the URL search parameters or local storage.
	 * If a sourceTag exists, stores other query parameters in localStorage along with the tag itself.
	 * If legacySourceTag exists, checks the dynamic info cookie and stores in localStorage along with the tag itself.
	 * @returns {string|null} The source tag or null if not found.
	 */
	static getSourceTag() {
		const searchParams = new URLSearchParams(location.search);
		const sourceTag = searchParams.get(QUERY_PARAM_KEYS.SOURCE_TAG);

		const legacyCookiesNames = [
			COOKIES.LEGACY_CAMPAIGN_ID_THREE,
			COOKIES.LEGACY_CAMPAIGN_ID_TWO,
			COOKIES.LEGACY_CAMPAIGN_ID
		];

		let legacySourceTag = null;
		for (const cookieName of legacyCookiesNames) {
			const cookieValue = Cookies.get(cookieName);
			if (cookieValue) {
				legacySourceTag = cookieValue;
				break;
			}
		}
		const cachedSourceTag = localStorage.getItem(LOCAL_STORAGE_KEYS.SOURCE_INFO);

		if (sourceTag) {
			const queryParams = {};
			for (const [key, value] of searchParams.entries()) {
				queryParams[key] = value;
			}

			delete queryParams.src_tag;
			localStorage.setItem(
				LOCAL_STORAGE_KEYS.SOURCE_INFO,
				JSON.stringify({
					id: sourceTag,
					values: queryParams
				})
			);
			return sourceTag;
		}

		if (legacySourceTag) {
			let sourceInfo = {};
			const dynamicInfoLegacy = Cookies.get(COOKIES.LEGACY_CAMPAIGN_VARS);

			if (dynamicInfoLegacy) {
				try {
					sourceInfo = JSON.parse(decodeURIComponent(dynamicInfoLegacy));
				} catch (err) {
					Sentry.captureException(err, {
						extra: {
							action: 'Error: Decoding Legacy Source Tag'
						}
					});
				}
			}
			localStorage.setItem(
				LOCAL_STORAGE_KEYS.SOURCE_INFO,
				JSON.stringify({
					id: legacySourceTag,
					values: sourceInfo
				})
			);
			return legacySourceTag;
		}

		try {
			const tag = JSON.parse(cachedSourceTag);
			return tag?.id || null;
		} catch (err) {
			return cachedSourceTag || null;
		}
	}

	/**
	 * Attaches a tracking pixel to the document by creating an iframe and writing the pixel content into it.
	 * @param {string} pixel - The HTML content of the tracking pixel to be attached.
	 * @returns {Promise<void>} A promise that resolves when the iframe has successfully loaded the pixel.
	 */
	static attachPixel(pixel) {
		return new Promise((resolve, reject) => {
			const iFrame = document.createElement('iframe');
			iFrame.width = 1;
			iFrame.height = 1;
			iFrame.loading = 'lazy';
			iFrame.fetchpriority = 'low';
			iFrame.frameborder = 0;
			iFrame.style.display = 'none';

			document.body.appendChild(iFrame);

			iFrame.addEventListener('load', function () {
				resolve();
			});

			iFrame.contentWindow.document.open();
			iFrame.contentWindow.document.write(pixel);
			iFrame.contentWindow.document.close();
		});
	}

	/**
	 * Fires a campaign pixel if the global privacy control is not enabled.
	 * Retrieves campaign information from local storage and sends it to the API service.
	 * If a pixel is returned, it tracks and attaches the pixel.
	 * @param {string} event - The event name to be tracked.
	 * @returns {Promise<void>} - A promise that resolves when the pixel has been processed.
	 * @throws Will capture and log any exceptions using Sentry.
	 */
	static async fireCampaignPixel(event) {
		if ((typeof navigator !== 'undefined' && navigator.globalPrivacyControl) || get(app)?.meta?.inApp) return;
		const campaignInfo = localStorage.getItem(LOCAL_STORAGE_KEYS.SOURCE_INFO);
		if (campaignInfo) {
			const payload = {
				event,
				campaignInfo: JSON.parse(campaignInfo)
			};

			try {
				const response = await ApiService.resolvePixelInfo(payload);
				if (response && response.pixel) {
					AnalyticsService.trackPixel('before', event, 'campaign');
					await AnalyticsService.attachPixel(response.pixel);
					AnalyticsService.trackPixel('after', event, 'campaign');
				}
			} catch (e) {
				Sentry.captureException(e, {
					extra: {
						action: 'Error: Fire Campaign Pixel'
					}
				});
			}
		}
	}

	/**
	 * Tracks a pixel event by sending a payload to the analytics service.
	 * @param {string} action - The action associated with the pixel event.
	 * @param {string} event - The specific event to be tracked.
	 * @param {string} type - The type of the event.
	 * @returns {Promise<void>} - A promise that resolves when the event is successfully fired.
	 */
	static trackPixel(action, event, type) {
		const campaignId = AnalyticsService.getSourceTag();

		const payload = {
			event_type: `${EVENT_NAMES.PIXEL_FIRE}-${action}`,
			event_payload: {
				aofl_product: AOFL_PRODUCT_NAMES.ABCMOUSE,
				event,
				type,
				campaignId
			}
		};

		return AnalyticsService.fireEvent({ event: payload });
	}

	/**
	 * Attribute the source tag to a campaign click event.
	 * @param {object|string} sourceTag - The source tag
	 * @returns {Promise<void>} - A promise that resolves when the event is tracked.
	 */
	static attributeSource(sourceTag) {
		const campaignInfo = localStorage.getItem(LOCAL_STORAGE_KEYS.SOURCE_INFO);
		const dynamicInfo = campaignInfo ? JSON.parse(campaignInfo).values : {};
		const event = {
			event_type: EVENT_NAMES.CAMPAIGN_CLICK,
			device_id: getDeviceId(),
			event_payload: {
				aofl_product: AOFL_PRODUCT_NAMES.ABCMOUSE,
				source_info: {
					source_tag: sourceTag,
					dynamic_info: { ...dynamicInfo }
				}
			}
		};

		return AnalyticsService.fireEvent({ event });
	}

	/**
	 * Tracks an event using the analytics service.
	 * @param {object} event - The event to track.
	 * @returns {Promise<void>} A promise that resolves when the event is tracked.
	 */
	static async fireEvent(event) {
		try {
			return ApiService.eventLog(event);
		} catch (e) {
			Sentry.captureException(e, {
				extra: {
					action: 'Error: Fire Event Log'
				}
			});
		}
	}

	static setCJCookie() {
		const searchParams = new URLSearchParams(location.search);
		const cjEvent = searchParams.get(QUERY_PARAM_KEYS.CJ_EVENT);
		if (cjEvent) {
			Cookies.set(COOKIES.CJ_COOKIE, cjEvent, COOKIES.CJ_CONFIG);
		}
	}

	/**
	 * Handles actions to be performed after navigation.
	 */
	static afterNavigate() {
		AnalyticsService.thirdPartyPageLoad();
		AnalyticsService.trackPageView();
		AnalyticsService.dataLayerPush({ eventName: 'global' });
	}

	/**
	 * Handles actions to be performed when the component is mounted.
	 */
	static async onMount() {
		AnalyticsService.setCJCookie();
		const sourceTag = AnalyticsService.getSourceTag();
		if (sourceTag) {
			Sentry.setTag('source', sourceTag);
			AnalyticsService.attributeSource(sourceTag);
		}
		await AnalyticsService.trackMigrationRotation();
		AnalyticsService.setClickTrackingListener();
		await AnalyticsService.trackEmailClick();
	}

	/**
	 * Handles actions to be performed when the component is destroyed.
	 */
	static async onDestroy() {
		AnalyticsService.removeClickTrackingListener();
	}
}

export { AnalyticsService };
