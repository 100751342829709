import { PAGES } from '$lib/utils/constants';

export default class APIError extends Error {
	/**
	 * API Custom Error class for handling specific error cases.
	 * @param {string} message - The error message.
	 * @param {number} errorCode - A custom error code.
	 * @param {object} [details] - Optional additional details about the error.
	 */
	constructor(message, errorCode, details) {
		super(APIError.getEnhancedErrorMessage(errorCode));

		this.name = this.constructor.name;
		this.errorCode = errorCode;
		this.details = details;
		this.cause = {
			message,
			errorCode,
			details
		};
		this.orignalMessage = message;

		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, this.constructor);
		}
	}

	/**
	 * @param {number} errorCode
	 */
	static getEnhancedErrorMessage(errorCode) {
		if (!errorCode) return APIError.UNKNOWN_API_ERROR.message;

		const foundError = APIError.API_ERROR_CODE_MAPPING.find((error) => error.code === errorCode);

		if (foundError) return foundError.message;
		else return APIError.UNKNOWN_API_ERROR.message;
	}

	static API_ERROR_CODE_MAPPING = [
		{
			code: 992302,
			message: 'Your payment was declined. Please try again or use a different payment method.'
		},
		{
			code: 992000,
			message: 'The email or password you have entered is incorrect.'
		},
		{
			code: 992316,
			message: `The email you entered is already associated with an account. Please log in <a id="error-existingLoginLink" class="text-oceanBlue underline" href=${PAGES.INTERNAL.LOGIN}>here</a> or use a different email.`
		},
		{
			code: 992315,
			message: `The email you entered is already associated with an account. Please log in <a id="error-existingLoginLink" class="text-oceanBlue underline" href=${PAGES.INTERNAL.LOGIN}>here</a> or use a different email.`
		},
		{
			code: 992601,
			message: `The email you entered is already associated with an account. Please log in <a id="error-existingLoginLink" class="text-oceanBlue underline" href=${PAGES.INTERNAL.LOGIN}>here</a> or use a different email.`
		}
	];

	static UNKNOWN_API_ERROR = {
		code: 100000,
		message: 'Sorry, an unknown error occurred. Please try again later.'
	};
}
